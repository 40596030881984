import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, IconButton, InputAdornment, Typography,
} from '@material-ui/core';
import { WatchLater } from '@material-ui/icons';
import { APPROVAL_STATUS } from '../../../approval/constant';
import { getClockInColor, getClockOutColor } from '../../../attendance/helper';
import {
  AccentButton, MapDrawer, FunctionalPage, SectionTitle,
} from '../../../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, DRAW_MODE_MARKER, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_DATE_TIME_FORMAT, PICKER_MODE_DATE_TIME, PICKER_MODE_TIME,
  RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_ID, RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_NUMBER,
  RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_STATUS,
  RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_STATUS_TIME,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_ID,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_NAME,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_STATUS,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_NOTES,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_REPORTED_LAT,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_REPORTED_LNG,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_STATUS,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_ID,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_NAME,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_STATUS,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_NOTES,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_REPORTED_LAT,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_REPORTED_LNG,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_STATUS,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_AFTER_MIN,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_BEFORE_MIN,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_HOUR,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_AFTER_MIN,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_BEFORE_MIN,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_HOUR,
  RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_HOUR_TIME_ZONE,
  RXFIELD_ATTENDANCE_CORRECTION_CLOCK_IN, RXFIELD_ATTENDANCE_CORRECTION_CLOCK_IN_TIME,
  RXFIELD_ATTENDANCE_CORRECTION_CLOCK_OUT, RXFIELD_ATTENDANCE_CORRECTION_CLOCK_OUT_TIME,
  RXFIELD_ATTENDANCE_CORRECTION_PIC_ID, RXFIELD_ATTENDANCE_CORRECTION_PIC_NAME,
  RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_DEPT, RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_DIVISION,
  RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_EMPLOYEE_ID, RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_ID,
  RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_NAME, RXFORM_ATTENDANCE_CORRECTION,
  RXSTATE_ATTENDANCE_CORRECTION_PAGE, RXSTATE_ATTENDANCE_CORRECTIONS,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormDateTimePickerField, renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const renderDialogContent = (initialValues, onDownloadAttachmentPressed) => (
  <div>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderApprovalId}
            label={LocalizedString.attendanceCorrectionPage.placeholderApprovalId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_NUMBER}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderApprovalNumber}
            label={LocalizedString.attendanceCorrectionPage.placeholderApprovalNumber}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderApprovalStatus}
            label={LocalizedString.attendanceCorrectionPage.placeholderApprovalStatus}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_STATUS_TIME}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.attendanceCorrectionPage.placeholderApprovalStatusTime}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_PIC_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderApprovalPicId}
            label={LocalizedString.attendanceCorrectionPage.placeholderApprovalPicId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_PIC_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderApprovalPicName}
            label={LocalizedString.attendanceCorrectionPage.placeholderApprovalPicName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_CLOCK_IN}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.attendanceCorrectionPage.placeholderClockIn}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_CLOCK_OUT}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.attendanceCorrectionPage.placeholderClockOut}
            disabled
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderRequesterId}
            label={LocalizedString.attendanceCorrectionPage.placeholderRequesterId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderRequesterName}
            label={LocalizedString.attendanceCorrectionPage.placeholderRequesterName}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_EMPLOYEE_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderRequesterEmployeeId}
            label={LocalizedString.attendanceCorrectionPage.placeholderRequesterEmployeeId}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_DIVISION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderRequesterDivision}
            label={LocalizedString.attendanceCorrectionPage.placeholderRequesterDivision}
            disabled
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_DEPT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderRequesterDepartment}
            label={LocalizedString.attendanceCorrectionPage.placeholderRequesterDepartment}
            disabled
          />
        </Grid>
        {initialValues.attachment && (
        <Grid item>
          <AccentButton
            onClick={() => onDownloadAttachmentPressed(initialValues)}
            caption={LocalizedString.attendanceCorrectionPage.buttonCaptionDownloadAttachment}
          />
        </Grid>
        )}
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.attendanceCorrectionPage.labelWorkingHour} />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Field
          name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_HOUR}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.attendanceCorrectionPage.placeholderStartWorkingHour}
          disabled
          pickerMode={PICKER_MODE_TIME}
        />
      </Grid>

      <Grid item sm md>
        <Field
          name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_HOUR}
          component={renderReduxFormDateTimePickerField}
          label={LocalizedString.attendanceCorrectionPage.placeholderEndWorkingHour}
          disabled
          pickerMode={PICKER_MODE_TIME}
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Field
        name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_HOUR_TIME_ZONE}
        component={renderReduxFormOutlinedTextField}
        label={LocalizedString.attendanceCorrectionPage.placeholderWorkingHourTimeZone}
        placeholder={LocalizedString.attendanceCorrectionPage.placeholderWorkingHourTimeZone}
        disabled
      />
    </Grid>

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_BEFORE_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderStartBeforeMin}
            label={LocalizedString.attendanceCorrectionPage.placeholderStartBeforeMin}
            disabled
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_AFTER_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderStartAfterMin}
            label={LocalizedString.attendanceCorrectionPage.placeholderStartAfterMin}
            disabled
            type="number"
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_BEFORE_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderEndBeforeMin}
            label={LocalizedString.attendanceCorrectionPage.placeholderEndBeforeMin}
            disabled
            type="number"
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_AFTER_MIN}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderEndAfterMin}
            label={LocalizedString.attendanceCorrectionPage.placeholderEndAfterMin}
            disabled
            type="number"
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.attendanceCorrectionPage.labelClockIn} />

    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item sm md>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_CLOCK_IN_TIME}
            component={renderReduxFormDateTimePickerField}
            label={LocalizedString.attendanceCorrectionPage.placeholderClockInTime}
            disabled
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderClockInStatus}
            label={LocalizedString.attendanceCorrectionPage.placeholderClockInStatus}
            disabled
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled edge="end">
                    <WatchLater style={{ color: getClockInColor(initialValues.clockInStatus) }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderLocationId}
            label={LocalizedString.attendanceCorrectionPage.placeholderLocationId}
            disabled
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderLocationName}
            label={LocalizedString.attendanceCorrectionPage.placeholderLocationName}
            disabled
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_STATUS}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderClockInStatus}
            label={LocalizedString.attendanceCorrectionPage.placeholderClockInStatus}
            disabled
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_NOTES}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.attendanceCorrectionPage.placeholderNotes}
            label={LocalizedString.attendanceCorrectionPage.placeholderNotes}
            disabled
            multiline
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item container>
          <Grid item sm md>
            <Field
              name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_REPORTED_LAT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.attendanceCorrectionPage.placeholderLatitutde}
              label={LocalizedString.attendanceCorrectionPage.placeholderLatitutde}
              disabled
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_REPORTED_LNG}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.attendanceCorrectionPage.placeholderLongitude}
              label={LocalizedString.attendanceCorrectionPage.placeholderLongitude}
              disabled
            />
          </Grid>
        </Grid>
        <Grid item>
          <Typography>{LocalizedString.attendanceCorrectionPage.placeholderMap}</Typography>
          <MapDrawer
            drawingMode={DRAW_MODE_MARKER}
            center={initialValues.clockInCenter}
            marker={initialValues.clockInMarker}
            disabled
            containerStyle={{ height: '300px' }}
          />
        </Grid>
      </Grid>
    </Grid>

    <SectionTitle title={LocalizedString.attendanceCorrectionPage.labelClockOut} />

    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item sm md>
            <Field
              name={RXFIELD_ATTENDANCE_CORRECTION_CLOCK_OUT_TIME}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.attendanceCorrectionPage.placeholderClockOutTime}
              disabled
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.attendanceCorrectionPage.placeholderClockOutStatus}
              label={LocalizedString.attendanceCorrectionPage.placeholderClockOutStatus}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton disabled edge="end">
                      <WatchLater
                        style={{ color: getClockOutColor(initialValues.clockOutStatus) }}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.attendanceCorrectionPage.placeholderLocationId}
              label={LocalizedString.attendanceCorrectionPage.placeholderLocationId}
              disabled
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_NAME}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.attendanceCorrectionPage.placeholderLocationName}
              label={LocalizedString.attendanceCorrectionPage.placeholderLocationName}
              disabled
            />
          </Grid>
          <Grid item sm md>
            <Field
              name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_STATUS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.attendanceCorrectionPage.placeholderClockOutStatus}
              label={LocalizedString.attendanceCorrectionPage.placeholderClockOutStatus}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_NOTES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.attendanceCorrectionPage.placeholderNotes}
              label={LocalizedString.attendanceCorrectionPage.placeholderNotes}
              disabled
              multiline
            />
          </Grid>
        </Grid>
        <Grid item sm md>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_REPORTED_LAT}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.attendanceCorrectionPage.placeholderLatitutde}
                label={LocalizedString.attendanceCorrectionPage.placeholderLatitutde}
                disabled
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_REPORTED_LNG}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.attendanceCorrectionPage.placeholderLongitude}
                label={LocalizedString.attendanceCorrectionPage.placeholderLongitude}
                disabled
              />
            </Grid>
          </Grid>
          <Grid item>
            <Typography>{LocalizedString.attendanceCorrectionPage.placeholderMap}</Typography>
            <MapDrawer
              drawingMode={DRAW_MODE_MARKER}
              center={initialValues.clockOutCenter}
              marker={initialValues.clockOutMarker}
              disabled
              containerStyle={{ height: '300px' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </div>
);

const AttendanceCorrectionPage = ({
  initialValues, pics, requesters,
  downloading, loadingPics, loadingRequesters,
  handleSubmit, onAppear, onAdvancedFilterPressed, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onChangePage, onChangePageSize, onChangePicText,
  onChangeRequesterText, onDownloadAttachmentPressed, onRefresh, onResetAdvancedFilterPressed,
  onSearchBarTextChanged, onSortPressed, onViewPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_ATTENDANCE_CORRECTIONS}
    uiPage={RXSTATE_ATTENDANCE_CORRECTION_PAGE}
    filterColumns={[
      {
        title: LocalizedString.attendanceCorrectionPage.labelApprovalStatus,
        field: 'approvalStatus',
        type: FILTER_TYPE_DROPDOWN,
        data: APPROVAL_STATUS,
      },
      {
        title: LocalizedString.attendanceCorrectionPage.labelRequester,
        field: 'requester',
        type: FILTER_TYPE_DROPDOWN,
        loading: loadingRequesters,
        data: requesters,
        onChangeFilterText: onChangeRequesterText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.attendanceCorrectionPage.labelPic,
        field: 'approvalPicId',
        type: FILTER_TYPE_DROPDOWN,
        loading: loadingPics,
        data: pics,
        onChangeFilterText: onChangePicText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.attendanceCorrectionPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_FORMAT,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.attendanceCorrectionPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.attendanceCorrectionPage.labelApprovalNumber, field: 'approvalNumber', sorting: !downloading },
      { title: LocalizedString.attendanceCorrectionPage.labelRequester, field: 'requester.fullName', sorting: !downloading },
      {
        title: LocalizedString.attendanceCorrectionPage.labelClockIn,
        field: 'clockIn',
        sorting: !downloading,
        render: ({ clockIn }) => (clockIn
          ? toMoment(clockIn).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.attendanceCorrectionPage.labelClockOut,
        field: 'clockOut',
        sorting: !downloading,
        render: ({ clockOut }) => (clockOut
          ? toMoment(clockOut).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.attendanceCorrectionPage.labelPic, field: 'approvalPicName', sorting: !downloading },
      { title: LocalizedString.attendanceCorrectionPage.labelApprovalStatus, field: 'approvalStatus', sorting: !downloading },
      { title: LocalizedString.attendanceCorrectionPage.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onViewPressed={onViewPressed}
    title={LocalizedString.attendanceCorrectionPage.title}
    disableCreate
    disableDelete
    disableEdit
    useFullWidth
    usefullWidthDialog
  >
    {renderDialogContent(initialValues, onDownloadAttachmentPressed)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_ATTENDANCE_CORRECTION,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(AttendanceCorrectionPage);

AttendanceCorrectionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  pics: PropTypes.arrayOf(SimpleDataShape).isRequired,
  requesters: PropTypes.arrayOf(SimpleDataShape).isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingPics: PropTypes.bool.isRequired,
  loadingRequesters: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangePicText: PropTypes.func.isRequired,
  onChangeRequesterText: PropTypes.func.isRequired,
  onDownloadAttachmentPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
};
